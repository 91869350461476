import axios from 'utils/axios';
import _ from 'lodash';

import * as routes from './routes';
import { parseUniqueByField } from 'utils/utils';

import { DuplicatedSubmissionResponse, UserInput } from 'types/types';

export const getCmsData = (widgetId: string) =>
  axios
    .post(routes.GET_CMS_DATA, JSON.stringify(widgetId))
    .then((res) => res.data);

export const getMakes = () =>
  axios
    .get(routes.GET_MAKES)
    .then((res) =>
      _.map(res.data, (el) => ({ name: el.name, value: el.brandCode }))
    );

export const getModels = (brandCode: UserInput['make']) =>
  axios
    .get(routes.GET_MODELS, { params: { brandCode } })
    .then((res) => parseUniqueByField(res.data, 'name', true));

export const isBlacklisted = (
  email: UserInput['email'],
  phone: UserInput['phone']
) =>
  axios
    .get(routes.IS_BLACKLISTED, { params: { email, phone } })
    .then((res) => res.data as boolean);

export const isDuplicatedSubmission = (
  email: UserInput['email'],
  phone: UserInput['phone'],
  make: UserInput['make'],
  model: UserInput['model']
) =>
  axios
    .get(routes.IS_DUPLICATED_SUBMISSION, {
      params: { email, phone, make, model },
    })
    .then((res) => res.data as DuplicatedSubmissionResponse);

export const getVehicles = ({
  make,
  model,
  year,
}: Pick<UserInput, 'make' | 'model' | 'year'>) =>
  axios
    .post(
      routes.GET_VEHICLES,
      JSON.stringify({
        brand: make,
        model,
        firstRegistrationYear: year,
      })
    )
    .then((res) => res.data);

export const getVehicleByLicensePlate = (licensePlate: string) =>
  axios
    .post(routes.GET_VEHICLE_BY_LICENSE_PLATE, JSON.stringify(licensePlate))
    .then((res) => res.data);

export const getAttributes = () =>
  axios.get(routes.GET_ATTRIBUTES).then((res) => res.data);

export const sendOtpCode = (phone: UserInput['phone']) =>
  axios
    .post(routes.PHONE_VERIFICATION, JSON.stringify({ phone }))
    .then((res) => res.data);

export const verifyPhoneNumber = ({
  phone,
  code,
}: {
  phone: UserInput['phone'];
  code: string;
}) =>
  axios
    .post(routes.PHONE_VERIFICATION, JSON.stringify({ phone, code }))
    .then((res) => res.data);

export const makeSubmission = (data: any) =>
  axios
    .post(routes.MAKE_SUBMISSION, JSON.stringify(data))
    .then((res) => res.data);

import { forwardRef } from 'react';
import cx from 'classnames';

type Props = {
  id?: string;
  className?: string;
  content: string;
};

const Headline = forwardRef<HTMLHeadingElement, Props>(
  ({ content, className, id }, ref) => {
    return (
      <h2 ref={ref} id={id} className={cx('text-2xl font-bold', className)}>
        {content}
      </h2>
    );
  }
);

export default Headline;

export enum Step {
  LicensePlate,
  Make,
  Model,
  Year,
  BodyType,
  Fuel,
  Gear,
  Type,
  ArticleKey,
  Mileage,
  Condition,
  Equipment,
  Email,
  FirstName,
  LastName,
  Phone,
  PhoneVerification,
  FinalView,
}

export enum Mode {
  LicensePlate,
  Brand,
}

export enum AuctionStatus {
  Incomplete = 'as24_incomplete',
  InReview = 'as24_in_review',
  Scheduled = 'as24_scheduled',
  Active = 'as24_active',
  PendingDecision = 'as24_pending_decision',
  PendingCounterOffer = 'as24_pending_counter_offer',
  Accepted = 'as24_accepted',
  Declined = 'as24_declined',
}

import _ from 'lodash';

import type { Translations } from 'contexts/CmsDataProvider';
import type { Locale, UserInput, VehicleResults } from 'types/types';

export const camelizeObject = (obj: Object) =>
  _.transform(obj, (acc: any, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    acc[camelKey] = _.isObject(value) ? camelizeObject(value) : value;
  });

export const numberWithSeparator = (number: number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '’');

export const parseUniqueByField = (
  rawData: any[],
  field: string,
  sortByName = false
) => {
  const uniqueElements = _.uniq(rawData.map((el) => el[field]));
  const formattedResult = uniqueElements
    .filter((el) => el)
    .map((el) => ({
      name: el,
      value: el,
    }));

  if (sortByName) {
    return _.sortBy(formattedResult, (el) => el.name.toLowerCase());
  }

  return formattedResult;
};

export const getOptionsFromAttributes = (
  type: 'bodyType' | 'fuel' | 'gear' | 'type',
  keys: VehicleResults['bodyTypes'] | string,
  locale: Locale,
  attributeContent: any
) => {
  if (_.isString(keys)) {
    const attributeData = _.find(
      attributeContent,
      (el) => el.type === type && el.translations.key === keys
    );

    return attributeData
      ? _.get(attributeData, `translations.${locale}`, keys)
      : keys;
  }

  return keys.map((obj) => {
    const attributeData = attributeContent.find(
      (el: any) => el.type === type && el.translations.key === obj.name
    );

    if (attributeData) {
      obj.name = attributeData.translations[locale];
    }

    return obj;
  });
};

export const getRelevantOptions = (
  type: 'bodyType' | 'fuel' | 'gear' | 'type',
  allVehicles: VehicleResults['all'],
  selectedOptions: Partial<Pick<UserInput, 'bodyType' | 'fuel' | 'gear'>> = {}
) => {
  // used for bodyType, fuel, gear, and type
  const relevantVehicles = _.filter(allVehicles, (vehicle: any) => {
    return _.every(selectedOptions, (value, key) => {
      return key === type || vehicle[key] === value;
    });
  });

  const relevantOptions = parseUniqueByField(relevantVehicles, type);

  return relevantOptions;
};

export const formatRelevantVehicles = (
  allVehicles: VehicleResults['all'],
  selectedParams: any = {},
  locale: Locale,
  attributeContent: any,
  t: Translations['t']
) => {
  const filteredVehicles = allVehicles.filter((vehicle: any) => {
    let isExact = true;

    for (let key in selectedParams) {
      if (selectedParams[key] && vehicle[key] !== selectedParams[key]) {
        isExact = false;
        break;
      }
    }

    return isExact;
  });

  const formattedResult = filteredVehicles.map((vehicle) => {
    const {
      articleKey,
      brand,
      model,
      type,
      bodyType,
      hp,
      gear,
      fuel,
      productionDate,
      productionDateTo,
      firstRegistrationDate,
      price,
    } = vehicle;

    if (attributeContent && locale) {
      return {
        articleKey,
        title: `${brand} ${model} ${type} ${getOptionsFromAttributes(
          'bodyType',
          bodyType,
          locale,
          attributeContent
        )}`,
        description: `${hp} ${t(
          'generalTextStrings',
          'hp'
        )} • ${getOptionsFromAttributes(
          'gear',
          gear,
          locale,
          attributeContent
        )} • ${getOptionsFromAttributes(
          'fuel',
          fuel,
          locale,
          attributeContent
        )}<br/>
        ${t(
          'generalTextStrings',
          'dashboard_car_card_production_date'
        )}: ${productionDate} - ${
          productionDateTo === '12/2100' && new Date().getFullYear() < 2090
            ? t('generalTextStrings', 'dashboard_car_card_production_now')
            : productionDateTo
        } • ${t(
          'generalTextStrings',
          'dashboard_car_card_first_registration'
        )}: ${firstRegistrationDate.split('.').pop()}<br/>
        CHF ${numberWithSeparator(price)}`,
      };
    }

    return {
      articleKey,
      title: `${brand} ${model} ${type} ${bodyType}`,
      description: `${hp} ${t('generalTextStrings', 'hp')} | ${gear} | ${fuel}`,
    };
  });

  return formattedResult;
};

export const focusNextField = (
  nextRef: any,
  type: 'select' | 'input' = 'select'
) => {
  const nextElement = nextRef.current;

  if (nextElement) {
    nextElement!.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
    setTimeout(
      () => {
        switch (type) {
          case 'select': {
            const button = nextElement.querySelector('button');
            if (button) {
              button.click();
            }
            break;
          }
          case 'input': {
            nextElement.focus();
            break;
          }
          default:
            break;
        }
      },
      type === 'select' ? 400 : 150
    );
  }
};

export const formatPhoneNumber = (val: string) => {
  const phone = (val as string).replace(/[^\d+]/g, '');

  return phone[0] === '+'
    ? phone
    : phone.slice(0, 2) === '00'
    ? phone.replace('00', '+')
    : phone[0] === '0'
    ? phone.replace('0', '+41')
    : phone.length === 9
    ? `+41${phone}`
    : `+${phone}`;
};

export const parseNumber = (val: string) => parseInt(val.replace(/’/g, ''), 10);

export const scrollToTop = () =>
  document
    .getElementById('container')
    ?.scrollTo({ top: 0, behavior: 'smooth' });

export const scrollToBottom = () =>
  document
    .getElementById('container')
    ?.scrollTo({ top: 9999, behavior: 'smooth' });

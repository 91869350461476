export const GET_CMS_DATA = '/getWidgetContent';
export const GET_MAKES = '/getAllMakes';
export const GET_MODELS = '/getAllModels';
export const IS_BLACKLISTED = '/isBlacklisted';
export const IS_DUPLICATED_SUBMISSION = '/isDuplicatedSubmission';
export const GET_VEHICLES = '/getAllVehicles';
export const GET_VEHICLE_BY_LICENSE_PLATE = '/getVehicleByLicensePlate';
export const GET_ATTRIBUTES = '/getAttributes';
export const PHONE_VERIFICATION = '/phoneVerification';
export const MAKE_SUBMISSION = '/makeSubmission';

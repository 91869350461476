import { useEffect, useState } from 'react';
import cx from 'classnames';

import StatusLine, { Stage } from 'components/StatusLine';
import Fade from 'components/ui/Fade';

import { Step } from 'enums/enums';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

type Props = {
  content: string;
  stage: Stage;
  step: Step;
  setStep: (step: Step) => void;
  onClose: () => any;
};

const Header: React.FC<Props> = ({
  stage,
  content,
  onClose,
  step,
  setStep,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const container = document.getElementById('container')!;

    const handleScroll = (e: Event) => {
      const target = e.target as HTMLElement;

      if (!target) {
        return;
      }

      if (target.scrollTop > 50 && !collapsed) {
        setCollapsed(true);
      } else if (target.scrollTop <= 50 && collapsed) {
        setCollapsed(false);
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [collapsed]);

  return (
    <header
      className={cx(
        'sticky top-0 left-0 w-full flex items-center justify-between px-6 pt-5 border-b border-border-light bg-background transition-all z-30',
        collapsed ? 'pb-7' : 'pb-5'
      )}
    >
      {!collapsed && (
        <Fade exit={false}>
          <h1 className="min-w-[280px] font-bold">{content}</h1>
        </Fade>
      )}
      {collapsed && (
        <Fade exit={false}>
          <StatusLine
            className="w-[280px]"
            stage={stage}
            setStep={setStep}
            step={step}
            collapsed
          />
        </Fade>
      )}

      <CloseIcon className="size-6 cursor-pointer" onClick={onClose} />
    </header>
  );
};

export default Header;
